<template>
	<!-- <v-sheet class="leads leads-container" style="height: calc(100vh - 88px)"> -->
	<div>
		<v-layout>
			<v-flex class="py-0">
				<div class="simple">
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="listingStatus"
						style="min-width: 274px !important"
						solo
						@change="statusFilterRows($event)"
						:menu-props="{ offsetY: true, contentClass: 'rounded-lg' }"
					>
						<template #item="{ item }">
							<div class="d-flex align-center py-1">
								<div class="mr-2" style="min-width: 60px">
									<v-chip class="px-2" dark :color="item.status_color">
										{{ item.status_count }}
									</v-chip>
								</div>
								<div class="fw-600 text-uppercase">{{ item.text }}</div>
							</div>
						</template>
					</v-select>
				</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex listing-right justify-content-end">
				<template v-if="selected.length > 0">
					<div>
						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							content-class="white-background"
							v-model="bulkCheck"
						>
							<!-- :close-on-content-click="false" -->
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon left>mdi-chevron-down</v-icon>
									Bulk Action
								</v-btn>
							</template>
							<v-list class="py-0">
								<template v-for="(item, i) in listingBulkAction">
									<v-list-item link :key="i" v-on:click="doAction(item.value)">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu>
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							v-on:click="clearSelection"
							color="red lighten-1"
							class="text-white float-right"
						>
							<v-icon left> mdi-close </v-icon>
							Clear Selections
						</v-btn>
					</div>
				</template>
				<template v-else>
					<v-btn
						depressed
						tile
						color="red darken-4"
						class="text-white"
						:disabled="pageLoading"
						v-on:click="goToCreate()"
						v-if="getPermission('lead:create')"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						:disabled="pageLoading"
						@click="viewTypedata"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn>
					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						@click="routeSetting"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="drawerFilter = true"
						:disabled="pageLoading"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<!-- <template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template> -->
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title v-on:click="referess"
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}
									</v-list-item-title>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						v-model="bulkCheck"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							v-if="bulkCheck"
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							@change="updateTable('vendor')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										@change="updateTableVisiblity('lead', cols.key)"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="summary_show = !summary_show"
						:disabled="pageLoading"
					>
						<v-icon>mdi-chart-box-outline</v-icon>
					</v-btn> -->
				</template>
			</v-flex>
		</v-layout>
		<v-layout>
			<v-flex class="ml-2 d-flex justify-content-start pr-1">
				<div class="mr-2 fw-600 my-auto">Filter By</div>
				<div class="flex-grow-custom">
					<v-autocomplete
						v-model="productType"
						clearable
						:items="filterProductType"
						v-on:click:clear="defaultFilter = {}"
						item-text="text"
						item-value="value"
						:menu-props="{ bottom: true, offsetY: true }"
						prepend-inner-icon="mdi-tag"
						outlined
						hide-details
						class="filterSelect"
						placeholder="Product Type"
						content-class="filterSelectContent"
						v-on:change="topBarFilter($event, 'product_type')"
					>
					</v-autocomplete>
				</div>
				<div class="flex-grow-custom ml-3">
					<v-autocomplete
						v-model="opportunity"
						clearable
						:items="filterOpportunity"
						v-on:click:clear="defaultFilter = {}"
						item-text="text"
						item-value="value"
						:menu-props="{ bottom: true, offsetY: true }"
						prepend-inner-icon="mdi-circle"
						:color="filterColorMange(opportunity)"
						:class="filterColorMangeIcon(opportunity)"
						outlined
						placeholder="Opportunity"
						hide-details
						class="filterSelect"
						content-class="filterSelectContent"
						v-on:change="topBarFilter($event, 'opportunity')"
					>
						<template v-slot:item="data">
							<template>
								<v-list-item-icon class="mr-0 my-1">
									<v-icon
										class="mdi-18px"
										:color="
											data.item.value == 'low-chance'
												? 'cyan'
												: data.item.value == '50-50'
												? 'green'
												: data.item.value == 'high-chance'
												? 'orange'
												: data.item.value == 'none'
												? 'blue'
												: 'red'
										"
										>mdi-circle</v-icon
									>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-html="data.item.text"></v-list-item-title>
								</v-list-item-content>
							</template>
						</template>
					</v-autocomplete>
				</div>

				<div
					class="flex-grow-custom ml-3"
					v-if="(currentUser && currentUser.role == 1) || currentUser.role == 4"
				>
					<!--  {{ user_filter }}
				<pre>{{ users }}</pre> -->
					<v-autocomplete
						v-model="user_filter"
						:items="users"
						clearable
						v-on:click:clear="defaultFilter = {}"
						:menu-props="{ bottom: true, offsetY: true }"
						outlined
						hide-details
						item-text="display_name"
						item-value="id"
						class="filterSelect"
						placeholder="Users"
						content-class="filterSelectContent rounded-lg"
						v-on:change="topBarFilter($event, 'users')"
					>
						<template #selection="data">
							<v-chip color="#dadfff" small v-bind="data.attrs" :input-value="data.selected">
								<v-avatar left v-if="data.item.profile_img">
									<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
									<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
								</v-avatar>
								<span class="fw-600 text-uppercase">{{ data.item.display_name }}</span>
							</v-chip>
						</template>
						<template #item="data">
							<div class="py-1 d-flex">
								<v-avatar size="36" class="mr-2">
									<v-img v-if="data.item.profile_img" :src="data.item.profile_img" />
									<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
								</v-avatar>
								<div>
									<div class="fw-600 text-uppercase">{{ data.item.display_name }}</div>
									<div class="fw-500 text-muted">{{ data.item.email }}</div>
								</div>
							</div>
						</template>
					</v-autocomplete>
				</div>
				<div class="flex-grow-custom ml-3">
					<v-autocomplete
						v-model="filter_source"
						v-on:click:clear="defaultFilter = {}"
						:items="source"
						clearable
						:menu-props="{ bottom: true, offsetY: true }"
						outlined
						hide-details
						item-text="text"
						item-value="value"
						class="filterSelect"
						placeholder="Source"
						content-class="filterSelectContent"
						v-on:change="topBarFilter($event, 'source')"
					>
					</v-autocomplete>
				</div>
				<div class="flex-grow-custom ml-3">
					<DateRangePicker
						:id="+new Date()"
						v-on:click:clear="clearFliterDate"
						hide-details
						v-on:clearDates="dateRangeClear"
						:clear="date_status"
						hide-top-margin
						clearable
						placeholder="Reminder"
						v-model="filter_reminder_date"
						v-on:change="
							($event) => {
								Array.isArray($event) && $event.length > 1 && topBarFilter($event, 'date');
							}
						"
					></DateRangePicker>
					<!-- :disablePastFrom="Array.isArray(filter_reminder_date) ? filter_reminder_date[0] : ''"-->
				</div>
				<div class="">
					<v-btn
						depressed
						color="white"
						class="red darken-4 ml-2"
						tile
						text
						small
						style="height: 30px; min-width: 0px !important"
						v-on:click="clearFliter"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-flex>
		</v-layout>

		<v-layout
			class="page-summary-lead page-summary"
			style="display: grid; grid-template-columns: repeat(7, 1fr)"
			:class="summary_show ? 'show mt-2' : ''"
		>
			<!-- <v-flex
				class="summary-details bg-light-red"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('all')"
			>
				<div class="name text-capitalize" v-if="statusData.text">
					All Leads
				</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" :color="statusData.status_color"
							>mdi-file-chart-outline</v-icon
						>
					</div>
					<div class="number red--text ms-2 mt-2">{{ statusData.status_count }}</div>
				</div>
			</v-flex> -->

			<template>
				<template v-for="(statusData, key) in listingStatus">
					<!-- summary-details bg-light-red -->
					<PageSummeryBox
						:key="key"
						:active="statusData.value == status ? true : false"
						:color="statusData.status_color"
						:text="statusData.text"
						:count="statusData.status_count"
						@click="statusData.value != status && statusFilterRows(statusData.value)"
					/>
				</template>
			</template>
		</v-layout>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Lead #, Company Name, Contact, Email, Phone, Website, Address, Source"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<!-- <template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>
					<ul>
						<li>
							Addvance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template> -->

		<template v-if="viewType == true">
			<LeadsKanban v-on:update:lead="update_lead($event)" @loaded="pageLoading = false" />
		</template>
		<template v-else>
			<Table
				v-if="getPermission('lead:view')"
				:page-loading="pageLoading"
				@toggleLoading="toggleLoading"
				:type="endpoint"
				delete-endpoint="lead/"
				detail-route="leads-detail"
				@open-dialog="update_lead($event)"
				@reload:content="filterRows"
				:dialog-update="true"
				@openReminder="open_reminder_drawer($event)"
				@openReminderlist="open_reminder_list($event)"
				:status-filter-data="statusFilterData"
			></Table>
			<LeadsListing v-if="false" :status-filter-data="statusFilterData"></LeadsListing>
		</template>
		<AdvanceFilter
			v-if="false"
			v-on:addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="lead"
			search-title="Lead"
		></AdvanceFilter>
		<!-- <CreateLead :drawer="drawerCreate" v-on:close="drawerCreate = false"></CreateLead> -->
		<!-- <CreateReminder
			v-if="open_reminder_dialog"
			:drawer="open_reminder_dialog"
			:title-status="meeting_title"
			:reminder-single-data="editReminderData"
			:attendies="attendieList"
			v-on:updateRemindSuccess="refreshReminder"
			v-on:close="open_reminder_dialog = false"
			related="lead"
			:related-id="lead_id"
			:name="lead_company"
		></CreateReminder> -->
		<CreateOrUpdateReminderV2
			v-if="open_reminder_dialog"
			:drawer="open_reminder_dialog"
			:reminderId="editReminderId"
			related="lead"
			v-on:success="refreshReminder"
			:related-id="lead_id"
			v-on:close="
				open_reminder_dialog = false;
				editReminderId = 0;
			"
		></CreateOrUpdateReminderV2>

		<template>
			<CreateDialog :dialog="updateDialog" :dialog-width="1400">
				<template v-slot:body>
					<UpdateLead
						:is-dialog="updateDialog"
						:leads-id="lead_id"
						v-on:close="updateDialog = false"
						v-on:refresh="
							updateDialog = false;
							getListing();
						"
					>
					</UpdateLead>
				</template>
			</CreateDialog>
		</template>

		<Dialog
			v-if="reminderListDialog"
			:dialog="reminderListDialog"
			:dialog-width="1200"
			:content-class="`testdata ${open_reminder_dialog ? 'd-none' : ''}`"
			@close="reminderListDialog = false"
		>
			<template v-slot:title>
				Leads Reminder<template v-if="lead_company"> :- {{ lead_company }}</template>
				<v-spacer></v-spacer>
			</template>
			<template v-slot:body>
				<v-layout class="px-4 py-2">
					<v-tabs
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent w-100"
						active-class="blue darken-4 text-white"
						hide-slider
						v-model="reminderTabs"
					>
						<v-tab href="#overdue" v-on:click="getReminder(reminderId, 1)">
							<v-icon small left>mdi-alarm-check</v-icon>Overdue ({{
								reminderCounting && reminderCounting.overdue_reminders_count
									? reminderCounting.overdue_reminders_count
									: 0
							}})</v-tab
						>
						<v-tab href="#upcoming" v-on:click="getReminder(reminderId, 2)">
							<v-icon small left>mdi-alarm-check</v-icon>upcoming ({{
								reminderCounting && reminderCounting.upcoming_reminders_count
									? reminderCounting.upcoming_reminders_count
									: 0
							}})</v-tab
						>
						<v-tab href="#complete" v-on:click="getReminder(reminderId, 3)">
							<v-icon small left>mdi-alarm-check</v-icon>Completed ({{
								reminderCounting && reminderCounting.completed_reminders_count
									? reminderCounting.completed_reminders_count
									: 0
							}})</v-tab
						>
					</v-tabs>
				</v-layout>
				<div class="tab-layout-content px-4">
					<v-tabs-items v-model="reminderTabs">
						<v-tab-item value="overdue">
							<template v-if="reminderData && reminderData.length > 0">
								<div v-for="(reminder, index) in reminderData" :key="index">
									<ReminderTab
										:open_details="!index ? true : false"
										:remind="reminder"
										v-on:editReminder="editReminderForm($event, reminder)"
										:index-reminder="index"
										v-on:reminderSuccess="refreshReminder"
										v-on:reminderSuccessNew="deleteRefreshReminder($event)"
										v-on:deleteSuccess="deleteRefreshReminder($event)"
									></ReminderTab>
								</div>
							</template>
							<template v-else>
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no reminder at the moment.
								</p>
							</template>
						</v-tab-item>
						<v-tab-item value="upcoming">
							<template v-if="reminderData && reminderData.length > 0">
								<div v-for="(reminder, index) in reminderData" :key="index">
									<ReminderTab
										:open_details="!index ? true : false"
										:remind="reminder"
										v-on:editReminder="editReminderForm($event, reminder)"
										:index-reminder="index"
										v-on:reminderSuccessNew="deleteRefreshReminder($event)"
										v-on:deleteSuccess="deleteRefreshReminder($event)"
									></ReminderTab>
								</div>
							</template>
							<template v-else>
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no reminder at the moment.
								</p>
							</template>
						</v-tab-item>
						<v-tab-item value="complete">
							<template v-if="reminderData && reminderData.length > 0">
								<div v-for="(reminder, index) in reminderData" :key="index">
									<ReminderTab
										:open_details="!index ? true : false"
										:remind="reminder"
										v-on:editReminder="editReminderForm($event, reminder)"
										:index-reminder="index"
										v-on:reminderSuccessNew="deleteRefreshReminder($event)"
										v-on:deleteSuccess="deleteRefreshReminder($event)"
									></ReminderTab>
								</div>
							</template>
							<template v-else>
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no reminder at the moment.
								</p>
							</template>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</template>
			<template v-slot:action>
				<v-btn class="close-btn" depressed tile v-on:click="closeDialog"> Close </v-btn>
			</template>
		</Dialog>
		<!-- </v-sheet> -->
	</div>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
import SettingsMixin from "@/core/mixins/settings.mixin";
import { /* GET, */ QUERY } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
import { toSafeInteger } from "lodash";
import moment from "moment-timezone";

/* import CreateLead from "@/view/pages/leads/create/CreateLead"; */
//import { LeadEventBus } from "@/core/lib/leadremark.lib";
/* import { RefressEventBus } from "@/core/lib/refress-event.lib.lib"; */
// import CreateReminder from "@/view/pages/leads/create/CreateReminder";

export default {
	name: "leads-listing",
	title: "Listing Leads",
	mixins: [ListingMixin, HeightMixin, SettingsMixin],
	data() {
		return {
			skipBottomHeight: 72,
			defaultFilter: {},
			reminderTabs: "overdue",
			reminderCounting: {},
			viewType: false,
			attendieList: {},
			summary_show: true,
			reminderId: 0,
			tagItems: ["Software", "SEO"],
			filter_tag: "Software",
			labelItems: ["Hot", "Warm", "Cold"],
			filter_label: "Hot",
			userItems: ["Everyone"],
			pageLoading: false,
			drawerFilter: false,
			lead_company: null,
			meeting_title: null,
			clearDate: false,
			drawerCreate: false,
			open_reminder_dialog: false,
			pageTitle: "Leads",
			date_status: Number(new Date()),
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			pageBreadcrumbs: [
				{ text: "Contacts", disabled: true },
				{ text: "Vendor", disabled: true },
			],
			endpoint: "lead",
			settingsEndpoint: "lead-setting",
			lead_id: 0,
			reminderListDialog: false,
			leadStatus: [],
			statusFilterData: [],
			reminderData: [],
			reminder_id: 0,
			editReminderData: {},
			editReminderId: 0,
			status: "all",
			updateDialog: false,
			filterProductType: [],
			filterOpportunity: [],
			dataValue: null,
			users: [],
			source: [],
			bulkCheck: false,
			lead_settings: undefined,
		};
	},

	methods: {
		goToCreate() {
			let filterQuery = this.$route.query;
			let user_filter = 0;
			let objQuery = { t: new Date().getTime() };
			if (filterQuery.user_filter) {
				user_filter = filterQuery.user_filter;
				objQuery.user_filter = user_filter;
			}

			this.$router.push({
				name: "create-lead",
				query: objQuery,
			});
			//this.pushToRoute('create-lead');
		},
		toggleLoading(event) {
			this.pageLoading = event;
		},
		clearFliterDate() {
			this.filter_reminder_date = null;
			this.$router.replace({ name: "leads", query: { ...this.$route.query, date: [] } });
			this.topBarFilter();
		},
		filterColorMange(label) {
			if (label == "50-50") {
				return "green";
			} else if (label == "high-chance") {
				return "orange";
			} else if (label == "none") {
				return "blue";
			} else if (label == "low-chance") {
				return "cyan";
			} else {
				return "red";
			}
		},
		filterColorMangeIcon(label) {
			if (label == "50-50") {
				return "green-icon";
			} else if (label == "high-chance") {
				return "orange-icon";
			} else if (label == "none") {
				return "blue-icon";
			} else if (label == "low-chance") {
				return "cyan-icon";
			} else {
				return "red-icon";
			}
		},
		dateRangeClear() {
			this.clearDate = false;
		},
		referess() {
			window.location.reload();
		},
		closeDialog() {
			this.reminderListDialog = false;
		},
		refreshReminder(row) {
			this.getReminder(this.reminderId, row.status);
			this.getListing();
		},
		deleteRefreshReminder(row) {
			this.getReminder(row.related_id, row.status);
			this.getListing();
		},

		open_reminder_list(data) {
			this.lead_company = data.company_name;
			this.reminderId = data.id;
			this.getReminder(data.id, 2);
			this.reminderListDialog = true;
			this.reminderTabs = "upcoming";
		},
		clearFliter() {
			this.date_status = Number(new Date());
			this.filter_reminder_date = null;
			(this.productType = null),
				(this.opportunity = null),
				(this.filter_source = null),
				(this.user_filter = 0),
				(this.defaultFilter = {}),
				this.$nextTick(() => {
					this.getListing();
				});
			this.$router.replace({
				name: "leads",
				query: { t: new Date().getTime(), viewType: this.viewType },
				date: [],
			});
		},
		update_lead(id) {
			this.updateDialog = true;
			this.lead_id = id;
		},
		viewTypedata() {
			if (this.viewType) {
				console.log("Kanban");
			}
			this.viewType = !this.viewType;
			let filterQuery = this.$route.query;
			this.$router.push({
				name: "leads",
				query: {
					...filterQuery,
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},
		editReminderForm(id, data) {
			this.lead_id = data.related_id;
			this.open_reminder_dialog = true;
			this.editReminderId = id;
			// this.reminderListDialog = false;

			// this.$store
			// 	.dispatch(GET, { url: `single-reminder/${id}/lead` })
			// 	.then((data) => {
			// 		this.editReminderData = data.reminder;
			// 		this.attendieList = data.attendie[0];
			// 		this.reminderListDialog = false;
			// 	})
			// 	.catch((error) => {
			// 		console.log({ error });
			// 	});
		},
		open_reminder_drawer(data) {
			this.lead_id = data.id ? data.id : 0;
			if (data && data.reminder) {
				this.reminder_id = data.reminder.id ? data.reminder.id : 0;
			}
			this.lead_company = data && data.company_name ? data.company_name : null;
			this.meeting_title = "Create";
			this.editReminderData = {};
			this.attendieList = {};
			this.open_reminder_dialog = true;
			if (this.lead_id > 0 && this.reminder_id) {
				this.editReminderForm(this.reminder_id, data.reminder);
				this.meeting_title = "Update";
			}
			/* this.meeting_title = name; */
		},
		/* getLeadSetting() {
			this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					this.filterProductType = data.product_type;
					this.filterProductType.unshift({
						text: "All",
						type: 4,
						value: "",
					});
					this.filterOpportunity = data.opportunity;
					this.filterOpportunity.unshift({
						text: "All",
						type: 3,
						value: "",
					});
					this.users = data.users;
					this.source = data.source;
					this.source.unshift({
						text: "All",
						type: 2,
						value: "",
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		statusFilterRows(value) {
			//this.$route.query.page ? this.$route.query.page : null,
			this.status = value;
			this.pageLoading = true;
			this.allFiltersStatus(value);
			this.$store
				.dispatch(QUERY, {
					url: `lead`,
					data: {
						status: value,
						date:
							this.filter_reminder_date && this.filter_reminder_date.length > 0
								? this.filter_reminder_date
								: this.$route.query.date,
						product_type: this.productType ? this.productType : null,
						opportunity: this.opportunity || null,
						user_filter: this.user_filter || null,
						source: this.filter_source || null,
						page: 1,
						search: this.listingSearch || undefined,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, 1);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					/* this.addvanceFilterAarry = []; */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		allFiltersStatus(value) {
			this.$router.push({
				name: "leads",
				query: {
					status: value,
					t: new Date().getTime(),
					date:
						this.filter_reminder_date && this.filter_reminder_date.length > 0
							? this.filter_reminder_date
							: this.$route.query.date,
					product_type: this.productType ? this.productType : null,
					opportunity: this.opportunity || null,
					user_filter: this.user_filter || null,
					source: this.filter_source || null,
					page: this.$route.query.page ? this.$route.query.page : null,
					search: this.listingSearch || undefined,
				},
			});
		},
		topBarFilter() {
			//this.$route.query.page ? this.$route.query.page : null,
			let filter = {
				date:
					this.filter_reminder_date && this.filter_reminder_date.length > 1
						? this.filter_reminder_date
						: this.$route.query.date,
				product_type: this.productType ? this.productType : null,
				opportunity: this.opportunity || null,
				user_filter: this.user_filter || null,
				source: this.filter_source || null,
				status: this.status,
				page: 1,
				search: this.listingSearch || undefined,
			};

			if (Array.isArray(this.filter_reminder_date) && this.filter_reminder_date.length > 1) {
				//filter?.date[0] && filter?.date[1]

				if (moment(filter.date[0]).isAfter(filter.date[1])) {
					this.$store.commit(SET_ERROR, [
						{ model: true, message: `Start Date can not be less than End Date` },
					]);
					//this.filter_reminder_date =  null;
					return false;
				}
			}

			/* 	const filterKey = type; */
			this.allFiltersSetting(filter);
			if (!this.viewType) {
				this.pageLoading = true;
				this.$store
					.dispatch(QUERY, {
						url: `lead`,
						data: filter /* {
						status: "all",
						[filterKey]: value,
					}, */,
					})
					.then((data) => {
						this.$store.commit(SET_ACTION, data.action);
						this.$store.commit(SET_BULK_ACTION, data.bulk_action);
						this.$store.commit(SET_CURRENT_PAGE, 1);
						this.$store.commit(SET_TOTAL_PAGE, data.total_page);
						this.$store.commit(SET_SHOWING_STRING, data.showing_string);
						this.$store.commit(SET_STATUS, data.status);
						this.$store.commit(SET_FILTER, data.filter);
						this.$store.commit(SET_TBODY, data.tbody);
						this.$store.commit(SET_THEAD, data.thead);
					})
					.catch((error) => {
						console.log({
							error,
						});
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
		},
		allFiltersSetting(filter) {
			this.$router.push({
				name: "leads",
				query: {
					date: filter.date,
					product_type: filter.product_type,
					opportunity: filter.opportunity,
					user_filter: filter.user_filter,
					source: filter.source,
					status: filter.status,
					t: new Date().getTime(),
					search: this.listingSearch || undefined,
				},
			});
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "lead",
				},
			});
		},
		getReminder(id, type) {
			if (!type) {
				this.reminderTabs = "upcoming";
				type = 1;
			}
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: { related_id: id, related_to: "lead", status: type ? type : 1 },
				})
				.then((data) => {
					if (type == 1) {
						this.reminderTabs = "overdue";
					} else if (type == 2) {
						this.reminderTabs = "upcoming";
					} else if (type == 3) {
						this.reminderTabs = "complete";
					}
					this.reminderData = data.rows;
					this.reminderCounting = data.countings ? data.countings : {};
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		// getReminder(id, type) {
		// 	this.$store
		// 		.dispatch(QUERY, {
		// 			url: `reminder`,
		// 			data: {
		// 				related_id: id,
		// 				related_to: "lead" /* , withOutExpired: "not-expired"  */,
		// 				status: type ? type : 1,
		// 			},
		// 		})
		// 		.then((data) => {
		// 			this.reminderData = data.rows;
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		});
		// },
	},
	components: {
		/* CreateLead, */
		/* CreateReminder, */
		ReminderTab: () => import("@/view/pages/leads/components/ReminderTab"),
		CreateDialog: () => import("@/view/components/Create-dialog"),
		AdvanceFilter: () => import("@/view/pages/leads/AdvanceFilter"),
		Dialog: () => import("@/view/components/Dialog"),
		LeadsKanban: () => import("@/view/pages/leads/LeadsKanban"),
		LeadsListing: () => import("@/view/pages/leads/LeadsListing"),
		CreateOrUpdateReminderV2: () => import("@/view/pages/leads/create/CreateOrUpdateReminderV2"),
		DateRangePicker: () => import("@/view/components/DateRangePickerV2"),
		UpdateLead: () => import("@/view/pages/leads/Create-Lead.vue"),
		PageSummeryBox: () => import("@/view/components/PageSummeryBox"),
	},
	created() {
		if (!Array.isArray(this.$route.query.date)) {
			if (this.$route.query.date)
				this.filter_reminder_date = [this.$route.query.date, this.$route.query.date];
		} else {
			this.filter_reminder_date = this.$route.query.date;
		}
	},
	computed: {
		...mapGetters(["currentUser", "moduleSettings"]),
	},
	watch: {
		moduleSettings: {
			immediate: true,
			handler(settings) {
				if (Object.keys(settings).length) {
					this.filterProductType = settings.product_type;
					this.filterProductType.unshift({
						text: "All",
						type: 4,
						value: "",
					});
					this.filterOpportunity = settings.opportunity;
					this.filterOpportunity.unshift({
						text: "All",
						type: 3,
						value: "all",
					});
					this.users = settings.users;
					this.source = settings.source;
					this.source.unshift({
						text: "All",
						type: 2,
						value: "",
					});
				}
			},
		},
	},
	beforeMount() {
		this.productType = this.$route.query.product_type;
		this.opportunity = this.$route.query.opportunity;
		this.filter_source = this.$route.query.source;
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		this.status = this.$route.query.status || "all";
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = toSafeInteger(this.$route.query.user_filter);
		}
		/* this.defaultFilter = {
			date:
				this.filter_reminder_date && this.filter_reminder_date.length > 0
					? this.filter_reminder_date
					: this.$route.query.date,
			product_type: this.productType ? this.productType : null,
			opportunity: this.opportunity || null,
			user_filter: this.user_filter || null,
			source: this.filter_source || null,
			status: this.status,
		
		}; */
	},
	mounted() {
		/* this.getLeadSetting(); */
		this.viewType = this.$route.query.viewType == "true" ? true : false;
		let filterQuery = this.$route.query;
		this.$router.push({
			name: "leads",
			query: {
				...filterQuery,
				viewType: this.viewType,
				t: new Date().getTime(),
			},
		});
		/* LeadEventBus.$on("table-lead-refresh", () => {
			this.getListing();
		}); */
		/* this.resetSearch(); */
	},
};
</script>
